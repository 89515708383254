import React from "react";
import PageWrapper from "../components/PageWrapper";
import Button from "../components/buttons/Button";
import styled from "styled-components";
import { Link } from "gatsby";
import DarkPageHeading from "../components/DarkPageHeading";

const Message = styled.div`
  margin-bottom: 30px;
`;

export default function PageNotFound() {
  return (
    <PageWrapper>
      <DarkPageHeading>Page Not Found</DarkPageHeading>
      <Message>Sorry, but this is page isn&apos;t one we know about.</Message>
      <Link to={"/"}>
        <Button>Go Back</Button>
      </Link>
    </PageWrapper>
  );
}
